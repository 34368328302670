import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs"; 

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private idSnipper: string;
  ShowDialog(mensaje: string, tittle: string = "Error"): void {
      mensaje = (mensaje == null) ? "" : mensaje;
      metroDialog.create({
          title: tittle,
          content: `<div style="min-width:300px;max-width: 600px;width:auto;">${mensaje}</div>` ,
          actions: [
              {
                  title: "Ok",
                  onclick: function (el: any): void {
                      $(el).data("dialog").close();
                  }
              }
          ],
          options: {    
              overlay: true,
              overlayColor: "op-dark",
              overlayClickClose: true,

          }
      });
  }

  ShowConfirm(mensaje: string, tittle: string = ""): Observable<boolean> {
      let subject: Subject<boolean> = new Subject();
      //let promesa = new Promise();
      metroDialog.create({
          title: tittle,
          content: `<div style="min-width:300px;max-width: 600px;width:auto;"><strong>${mensaje}<strong></div>`,
          actions: [
              {
                  title: "Ok",
                  onclick: function (el: any): void {
                      $(el).data("dialog").close();
                      // observable.
                      subject.next(true);
                  }
              },
              {
                  title: "Cancel",
                  onclick: function (el: any): void {
                      $(el).data("dialog").close();
                      subject.next(false);
                  }
              }
          ],
          options: {
              overlay: true,
              overlayColor: "op-dark",
              overlayClickClose: false
          }
      });
      return subject;
  }


  ShowConfirmYesNo(mensaje: string, tittle: string = ""): Observable<boolean> {
      let subject: Subject<boolean> = new Subject();
      metroDialog.create({
          title: tittle.toLocaleUpperCase(),
          content: `<div style="min-width:300px;max-width: 600px;width:auto;"><strong>${mensaje}</strong></div>`,
          actions: [
              {
                  title: "Si",
                  onclick: function (el: any): void {
                      $(el).data("dialog").close();
                      // observable.
                      subject.next(true);
                  }
              },
              {
                  title: "No",
                  onclick: function (el: any): void {
                      $(el).data("dialog").close();
                      subject.next(false);
                  }
              }
          ],
          options: {
              overlay: true,
              overlayColor: "op-dark",
              overlayClickClose: false
          }
      });
      return subject;
  }

  Snniper(): void {
      if (this.idSnipper === void 0) {
          var dialog = metroDialog.create({
              //  title: "",
              content: "<div data-role='preloader' data-type='cycle' data-style='color'></div>"
              //  actions: [],
              //  options: {}
          });
          this.idSnipper = $(dialog["0"]).attr("id");
      } else {
          metroDialog.open("#"+this.idSnipper);
      }
  }

  SnniperClose(): void {
      if (this.idSnipper != undefined) {
          metroDialog.close("#"+this.idSnipper);
          this.idSnipper = undefined;
      }
  }

  // Notificacion(mensaje:string) {
  //     $.Notify({
  //         // caption: 'Notify title',
  //         content: mensaje,
  //         // type: typeNotificion,
  //         keepOpen: true
  //     });
  // }

  NotificacionSucess(mensaje: any, keepOpen: boolean = false): void {
      $.Notify({
          // caption: 'Notify title',
          content: mensaje,
          type: "success",
          keepOpen: keepOpen,
          timeout: 30000
      });
  }

  NotificacionAlert(mensaje: any, keepOpen: boolean = false): void {
      $.Notify({
          // caption: 'Notify title',
          content: mensaje,
          type: "alert",
          keepOpen: keepOpen,
          timeout: 30000
      });
  }

  NotificacionWarning(mensaje: string, keepOpen: boolean = false): void {
      $.Notify({
          // caption: 'Notify title',
          content: mensaje,
          type: "warning",
          keepOpen: keepOpen,
          timeout: 30000
      });
  }

  NotificacionInfo(mensaje: any, keepOpen: boolean = false): void {
      $.Notify({
          // caption: 'Notify title',
          content: mensaje,
          type: "info",
          keepOpen: keepOpen,
          timeout: 30000
      });
  }

  NotificacionWarningTitle(mensaje: string, title: string, keepOpen: boolean = false): void {
    $.Notify({
      caption: title,
      content: mensaje,
      type: "warning",
      keepOpen: keepOpen,
      timeout: 30000
    });
  }
}

