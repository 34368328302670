import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallHttp } from './service/call-http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorSpinnerService } from './service/interceptor-spinner.service';
import { DialogService } from './service/dialog.service';
import { AccesoDenegadoComponent } from './components/acceso-denegado/acceso-denegado.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ManageHttpInterceptor } from './service/manage-http-interceptor';
import { HttpCancelService } from './service/http-cancel.service';
import { AUTHENTICATION } from './service/vesta.inject';



@NgModule({
  declarations: [AccesoDenegadoComponent, PageNotFoundComponent],
  imports: [
    CommonModule,HttpClientModule
  ],
  providers: [CallHttp, DialogService, HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorSpinnerService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
  ],
  exports: [AccesoDenegadoComponent, PageNotFoundComponent]
})

export class VestaModule {
  static forRoot(settings: { isAuthentication: boolean } = { isAuthentication: false }): ModuleWithProviders {

    return {
      ngModule: VestaModule,
      providers: [
        { provide: AUTHENTICATION, useValue: settings }
      ]
    }
  }
}
