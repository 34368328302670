import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getRoutesCronometros } from './screen/routes';
const routes: Routes = [

  { path: '', redirectTo: '', pathMatch: 'full' }


];
//Esta clase se conecta a Routes.ts que es donde se encuentran las rutas del proyecto
function getRoutes() {
  let routesDemand = getRoutesCronometros();
  let allRoutes = routes.concat(routesDemand);
  return allRoutes;
};
@NgModule({
  imports: [RouterModule.forRoot(getRoutes())],
  exports: [RouterModule]
})
export class AppRoutingModule { }
