import { Injectable } from '@angular/core';
import { Router, ActivationEnd, ActivationStart } from '@angular/router';
import { HttpCancelService } from './http-cancel.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {   
  constructor(private router: Router, private httpCancelService: HttpCancelService) {
    router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.httpCancelService.cancelPendingRequests();
      }
    })    
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
  }
}
