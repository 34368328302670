<router-outlet></router-outlet>

<!--<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Factura Template</title>
</head>
<body style=" background-color: #f5f5f5; margin: 0px; padding: 0px;">
  <div style=" background-color: #fff; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)">-->
    <!--Header Section-->
    <!--<table style="width: 100%; padding: 0px 10px; background-color: #F5F5F5; border-spacing: 0; width: 100%; margin-bottom: 20px; background-color: #F5F5F5; border-collapse: collapse;">
      <tr>
        <td style="vertical-align: middle; text-align: left;">
          <img style="height: 60px;" src="" />
        </td>
        <td>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">600 metros del estadio nacional</span></div>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">Tegucigalpa, Honduras</span></div>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">504 - 2269-1600</span></div>
        </td>
        <td>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">Centro comercial Megaplaza</span></div>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">7mo. piso, San Pedro Sula, Honduras</span></div>
          <div><span style="font-family: Calibri; font-size: 14px; color: #A3A3A3">+504-33794430</span></div>
        </td>
        <td style="vertical-align: middle; text-align: right;">
          <a href="https://example.com" style="background-color: lightgray; border: 1px solid; border-radius: 5%; font-weight: bold; font-size: 16px; padding: 10px 20px; text-align: center; display: inline-block; width: auto;">
            <span style="color: #E93636;">Ir al Sistema</span>
          </a>
        </td>
      </tr>
    </table>-->

    <!--Title Section-->
    <!--<div><span style="font-family: Calibri; color: #3B3B3B; font-size: 40px; padding-left: 15px;">Notificación de Registro</span></div>-->

    <!--Client and Invoice Info-->
    <!--<table style="width: 100%; border-collapse: collapse; padding-left: 15px; padding-right: 15px; ">
      <tr>
        <td style="padding: 10px; vertical-align: top;">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Cliente</span></div>
          <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">Livsmart</span></div>
        </td>

        <td style="padding: 10px; vertical-align: top;">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Fecha de Envío</span></div>
          <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">Agosto 31, 2024</span></div>

        </td>

        <td style="padding: 10px; vertical-align: top; text-align: right;">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Fecha Fin</span></div>
          <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">31/08/2024</span></div>
        </td>

        <td style="padding: 10px;  text-align: center;" rowspan="2">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Monto Total</span></div>
          <div><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">$82,477</span></div>
        </td>
      </tr>
      <tr>
        <td style="padding: 10px; vertical-align: top;">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Usuario</span></div>
          <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">Juan Carlos Espinal</span></div>
        </td>
        <td style="padding: 10px; vertical-align: top;">
          <div><span style="font-family: Calibri; color: #A3A3A3; font-size: 14px;">Fecha Inicio</span></div>
          <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">26/08/2024</span></div>
        </td>

      </tr>
    </table>
    <br />
    <br />

    <div><span class="font-title-mont-header-24" style="padding-left: 15px">Detalle facturas</span></div>-->
    <!--Invoice Details Table-->
    <!--<div style="padding-right: 15px; padding-left: 15px;">
      <table style=" width: 100%; margin-bottom: 20px; background-color: #F5F5F5; border-collapse: collapse;">
        <thead style="border: none;">
          <tr>
            <th><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">Descripción</span></th>
            <th><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">Tipo de Factura</span></th>
            <th><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">Hora</span></th>
            <th><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">Proveedor</span></th>
            <th><span style="font-family: Calibri; color: #1B3280; font-size: 16px;">Monto</span></th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">Green Beans Per Bag</span><br />
              <span style="font-family: Calibri; color: #A3A3A3; font-size: 12px;">Green beans + Grain Bag + Goni Bag</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px; text-align: center">333</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #1B3280; font-size: 14px;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">$81585</span>
            </td>
          </tr>

          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">Forwarding</span><br />
              <span style="font-family: Calibri; color: #A3A3A3; font-size: 12px;">Factory to Soekarno hatta Port + Soekarno Hatta Port to Brisbane Port</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">4</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #1B3280; font-size: 14px;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">$680</span>
            </td>
          </tr>

          <tr style="border-bottom: 1px solid #ddd;">
            <td>
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">Documents</span><br />
              <span style="font-family: Calibri; color: #A3A3A3; font-size: 12px;">SKA (Local Certificate of Origin) + ETK (Local Export Legalization)</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">8</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">2</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #1B3280; font-size: 14px;">Vesta Customs S.A</span>
            </td>
            <td style="text-align:center">
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">$212</span>
            </td>
          </tr>
        </tbody>
        <tfoot style=" border: none;">
          <tr>
            <th colspan="4" style="text-align: end; padding: 10px">
              <span style="font-family: Calibri; color: #1B3280; font-size: 14px;">Total Amount</span>
            </th>
            <th>
              <span style="font-family: Calibri; color: #3B3B3B; font-size: 14px;">$82477</span>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>

    <br />-->
    <!--Terms and Conditions-->
    <!--<div style="padding-left: 15px;">
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 24px;">Términos & Condiciones</span></div>
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">Lorem Ipsum</span></div>
    </div>

    <br />-->
    <!--Partner Information-->

    <!--<div style="padding-left: 15px;">
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 24px;">Partner</span></div>
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">Isabella Gutierrez</span></div>
      <div><span style="font-family: Calibri; color: #3B3B3B; font-size: 16px;">igutierrez@grupovesta.net</span></div>
    </div>

    <br />-->
    <!--Footer-->
    <!--<table style="width: 100%; border-collapse: collapse;">
      <tr>
        <td style="padding: 10px; vertical-align: top; width:10% ">
          <div>
          </div>
        </td>
        <td style="padding: 10px; vertical-align: top; text-align: center; width: 80%">
          <div>
            <span style="font-family: Calibri; color: #1B3280; font-size: 32px;">www.grupovesta.com</span>
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding: 10px; vertical-align: top; width:10% ">
          <div>
            <span style="font-family: Calibri; color: #A4A4A4; font-size: 28px;">A1.03.09.24</span>
          </div>
        </td>
        <td style="padding: 10px; vertical-align: top; width:80%; text-align: center ">
          <div>
            <span style="font-family: Calibri; color: #3B3B3B; font-size: 24px;">Honduras - El Salvador - Guatemala - Nicaragua</span>
          </div>
        </td>
      </tr>
    </table>
    <br />
  </div>
</body>
</html>-->
