import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AUTHENTICATION } from './vesta.inject';

@Injectable({
  providedIn: 'root'
})
export class CallHttp {

  constructor(private http: HttpClient,
    @Inject(AUTHENTICATION) private autentication: any) { }

  httpGet<TResponse>(url: string, param: string = null, optionsT: { withCredentials: boolean } = null): Observable<TResponse> {
    let urlGet: string = (param == null) ? url : url + "?" + param;
    let resp: TResponse = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };
    var retorna: Observable<TResponse> = this.http.get<TResponse>(urlGet, options)//: Observable<TResponse> =
      .pipe(catchError(this.handleError("Error", resp)));

    return retorna;
  }

  httpGetParams<TResponse>(url: string, object: any, optionsT: { withCredentials: boolean } = null): Observable<TResponse> {
    let params = new HttpParams({ fromObject: object });
    let resp: TResponse = null;

    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };
    var retorna: Observable<TResponse> = this.http.get<TResponse>(url, { params: params, withCredentials: options.withCredentials })//: Observable<TResponse> =
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpGetText(url: string, param: string = null, optionsT: { withCredentials: boolean } = null): Observable<string> {
    let urlGet: string = (param == null) ? url : url + "?" + param;
    let resp = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };

    var retorna: Observable<string> = this.http.get(urlGet, { responseType: 'text', withCredentials: options.withCredentials })
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpPost<TResponse>(url: string, contentBody: any = {}, optionsT: { withCredentials: boolean } = null): Observable<TResponse> {
    let resp: TResponse = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };

    var retorna: Observable<TResponse> = this.http.post<TResponse>(url, contentBody, options)
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpPut<TResponse>(url: string, contentBody: any, options = { withCredentials: true }): Observable<TResponse> {
    let resp: TResponse = null;

    var retorna: Observable<TResponse> = this.http.put<TResponse>(url, contentBody)
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpPutFileShare<TResponse>(url: string, contentBody: any, contentType: string): Observable<TResponse> {
    let resp: TResponse = null;
    //Content-type
    const headers = new HttpHeaders({ 'Content-type': contentType });
    var retorna: Observable<TResponse> = this.http.put<TResponse>(url, contentBody,
      { headers: headers, withCredentials: false })
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }


  httpPutFileShareProgress(url: string, contentBody: any, contentType: string): Observable<any> {
    let resp = null;
    //Content-type
    const headers = new HttpHeaders({ 'Content-type': contentType });
    var retorna = this.http.put(url, contentBody,
      { headers: headers, withCredentials: false, reportProgress: true, observe: 'events' })
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpPostFormData<TResponse>(url: string, contentBody: any, optionsT: { withCredentials: boolean } = null): Observable<TResponse> {
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    headers.append('Accept', 'application/json');
    let resp: TResponse = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };
    //headers ?: Headers
    //const options = new RequestOptions({ headers: headers });

    var retorna: Observable<TResponse> = this.http.post<TResponse>(url, contentBody, { headers: headers, withCredentials: options.withCredentials })
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpPostText(url: string, contentBody: any, optionsT: { withCredentials: boolean } = null): Observable<string> {
    let resp = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };

    var retorna: Observable<string> = this.http.post(url, contentBody, { responseType: 'text', withCredentials: options.withCredentials })
      .pipe(catchError(this.handleError("Error", resp)));
    return retorna;
  }

  httpGetFile(url: string, fileName: string, optionsT: { withCredentials: boolean } = null): Observable<void> {
    let resp = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };

    var retorna = this.http.get(url, { responseType: 'blob', withCredentials: options.withCredentials })
      .pipe(catchError(this.handleError("Error", resp)), map(m => {
        this.downloadFile(m, fileName)
      }))//.map(m => );

    return retorna;
  }

  httpPostFile(url: string, fileName: string, contentBody: any = {}, optionsT: { withCredentials: boolean } = null): Observable<void> {
    let resp = null;
    let options = optionsT != null ? optionsT : { withCredentials: this.autentication.isAuthentication };
    var retorna = this.http.post(url, contentBody, { responseType: 'blob', withCredentials: options.withCredentials })
      .pipe(catchError(this.handleError("Error", resp)), map(m => {
        this.downloadFile(m, fileName)
      }))//.map(m => );

    return retorna;
  }

  private downloadFile(blob: Blob, filename: string) {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      let msj = "";
      if (error.error) {
        if (error.error.Message == undefined && error.statusText != "Unknown Error" && (error.status == 404 || error.status == 412)) {
          var keysObject = Object.keys(error.error);
          for (let k of keysObject) {
            msj += k + " - " + (error.error[k] as Array<string>).reduce((r, n, i) => r + "<br />" + n) + "<br />";
          }
        } else if (error.error.text) {
          msj = error.error.text;
        } else {
          msj = error.message;
        }
        if (error.error.Message != undefined) msj = error.error.Message;
        if (msj == "") msj = `${error.status} - ${error.statusText}`;

        //msj = error.error.Message != undefined ? error.error.Message : (msj == "") ? error.message : msj;
      } else {
        msj = error.message;
      }

      return throwError(msj); //of(result as T);
    };
  }


  public static isJsonString(str): boolean {
    try {
      str.json()
    } catch (e) {
      return false;
    }
    return true;
  }
}
