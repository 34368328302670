import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent  } from '@angular/common/http';
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InterceptorSpinnerService  implements HttpInterceptor{
  Snniper: number;
  constructor(private dialogService: DialogService) {
    this.Snniper = 0;
   }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.RequestInterceptor();
    const handler = req.clone();
    return next.handle(handler).pipe(finalize(() => { this.ResponseInterceptor() }));
}

private RequestInterceptor(): void {
    this.Snniper += 1;
    if (this.Snniper == 1) {
        this.dialogService.Snniper();
    }
}

private ResponseInterceptor() {
    if (this.Snniper > 0) {
        this.Snniper -= 1;
    }
    if (this.Snniper == 0) {
        this.dialogService.SnniperClose();
    }
}
}
